<template>
<div class="vendors">
  <div class="page-header">
    <h1>{{ $t('Settings') }} > {{ $t('Staff Record')}} </h1>
  </div>
  <div class="page-content">
    <div class="content-header">
      <h2>{{ $t('Staff Record') }}</h2>
    </div>

    <div class="promotions-list">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t('Member ID') }}</th>
            <th scope="col">{{ $t('Member Name') }}</th>
            <th scope="col">{{ $t('Account Name') }}</th>
            <th scope="col">{{ $t('Action Type') }}</th>
            <th scope="col">{{ $t('Action Date') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>7</td>
            <td>Chit Chiu</td>
            <td>chitch</td>
            <td>Login</td>
            <td>2024-08-11 12:12:16</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ng Chi Kwong</td>
            <td>ngchikwong</td>
            <td>Update Menu (Meal)</td>
            <td>2024-08-10 11:05:12</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ng Chi Kwong</td>
            <td>ngchikwong</td>
            <td>Update Menu (Meal)</td>
            <td>2024-08-10 11:00:00</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Chung Choi Fong</td>
            <td>chungchoi</td>
            <td>Change System Setting</td>
            <td>2024-07-10 14:17:23</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Chung Choi Fong</td>
            <td>chungchoi</td>
            <td>Login</td>
            <td>2024-07-10 14:00:12</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ng Chi Kwong</td>
            <td>ngchikwong</td>
            <td>Update Menu (Meal)</td>
            <td>2024-06-20 14:20:30</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ng Chi Kwong</td>
            <td>ngchikwong</td>
            <td>Login</td>
            <td>2024-06-20 16:12:40</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ng Chi Kwong</td>
            <td>ngchikwong</td>
            <td>Update Menu (Meal)</td>
            <td>2024-05-12 15:00:21</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ng Chi Kwong</td>
            <td>ngchikwong</td>
            <td>Update Menu (Seat Menu)</td>
            <td>2024-05-12 14:20:30</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ng Chi Kwong</td>
            <td>ngchikwong</td>
            <td>Login</td>
            <td>2024-05-06 13:12:40</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Chung Choi Fong</td>
            <td>chungchoi</td>
            <td>Login</td>
            <td>2024-06-02 13:17:23</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Chit Chiu</td>
            <td>chitch</td>
            <td>Login</td>
            <td>2024-06-15 15:02:26</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Chan Yick Wai</td>
            <td>chanyick</td>
            <td>Login</td>
            <td>2024-06-15 19:05:12</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';

export default {
name: 'Vendors',
components: {
  ItemFilter,
},
methods: {
  formatDateTime(timestamp, format){
    return Common.formatDateTime(timestamp, format);
  },
},
data(){
  return {
    activeName: 'All',
    displayCreateForm: false,
    displayUpdateForm: false,
    vendors: [],
    orignalVendors: [],
  }
},
computed: mapState({
 apiUrl: state => state.apiUrl,
}),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
